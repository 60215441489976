import React from "react";
import "./products.css";
import { Link, BrowserRouter as Router, Route } from "react-router-dom";

function Product({ ig2, text, link,data,heading }) {
  return (
    <>
      <div className="datala__products-item">
        <Link to={"/products/" + link} state={{from:data, heading:heading, img:ig2,}}>
          <img src={ig2} alt="" />
        </Link>
        <p>{text}</p>
      </div>
    </>
  );
}

export default Product;




