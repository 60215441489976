import React from "react";
import "./cta.css";

function cta() {
  return (
    <div className="cta__ " id="contact">
      <div className="cta__heading">
        <h1>Get In Touch With Us</h1>
        <p>
          Talk to us to enquire on how we can support your coding and marking
          needs.
        </p>
      </div>
      <div className="cta__button">
        <a href="/contact">
          <p>Enquire Now</p>
        </a>
      </div>
    </div>
  );
}

export default cta;
