import React from "react";
import "./items.css";
import ig from "../../assets/ci5500.jpeg";
import ig1 from "../../assets/1_EBS_260(s)_large.jpg";
import ig2 from "../../assets/EBS_250_main_01_hand.jpg";
import { Link,Routes, Route, useNavigate } from "react-router-dom";
export default function Items() {
  const navigate = useNavigate();

  const handleClick = () => {
    // 👇️ navigate programmatically
    navigate("/products");
  };
  return (
    <div className="datala__products-heading">
      <h1>Our Products</h1>
      <div className="datala__products" id="products">
        <div className="datala__products-item">
          <p>CITRONIX PRINTERS</p>
            <Link
             to={"/products/citronix"}
            state={{
              from: "Citronix offers a comprehensive range of continuous ink jet (CIJ) printers and inks to meet all your coding and marking needs. Citronix printers offer a non-contact way to code your products, whether plastic, metal, paper, wood, glass or other surface type, with one to five lines of high quality text, graphics and bar codes at very high speeds.",
              heading: "CONTINUOUS INKJET PRINTERS",
              img: ig,
            }}
          >
          <img src={ig} alt="" />
          </Link>
        </div>
        <div className="datala__products-item">
          <p>LARGE CHARACTER INK PRINTERS (LCP) PRINTERS</p>
            <Link
            to={"/products/ebs260"}
            state={{
              from: "Whatever surface needs to be marked or coded, the HandJet EBS-260 will print precisely and clearly; concrete, metal, glass, textiles, plastic, cardboard or wood. You name it…the HandJet EBS-260 can mark it 7 to 52mm high anywhere in your office or anywhere you can imagine.",
              heading: "EBS 260",
              img: ig1,
            }}
          >
          <img src={ig1} alt="" />
          </Link>
        </div>
        <div className="datala__products-item">
          <p>EBS 260 INDUSTRIAL HAND INKJET</p>
            <Link
            to={"/products/citronix"}
            state={{
              from: "Whatever surface needs to be marked or coded, the HandJet EBS-250 will print precisely and clearly; Concrete, metal, glass, textiles, plastic, cardboard or wood. You name it…the HandJet EBS-250 can mark it 7 to 27mm high in your office or anywhere you can imagine.",
              heading: "EBS 250",
              img: ig2,
            }}
          >
          <img src={ig2} alt="" />
          </Link>
        </div>
      </div>

      <a href="/products" className="btn"><p>View More Products</p></a>
      {/* <div>
        <button onClick={handleClick} className="btn">
          View More Products
        </button>
      </div> */}
    </div>
  );
}
