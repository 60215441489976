import React from "react";
import "./footer.css";

function footer() {
  return (
    <div className="datala_footer section__padding" id="about">
      <div className="datala_footer-content">
        <div>
          <h4>Contact</h4>
          <p>55 Broughton Drive Sunridge, Harare, Zimbabwe</p>
          <p>+263 772 374 129</p>
          <p>+971 58 579 1936</p>
          <p>+278 4077 7635</p>
          <p>cham.runhare@gmail.com</p>
          <p>datalaent@gmail.com</p>
          <p>info@datalaent.com</p>
        </div>
        <div>
          <h4>Supplier Websites</h4>
          <p>    <a href="http://www.citronix.co.za" target="_blank" rel="noreferrer">Citronix</a></p>
          <p>
            <a href="http://www.signea.co.za" target="_blank" rel="noreferrer">EBS (Signea SYSTEME)</a>
          </p>
        </div>
        <div>
          <h4>About Datala</h4>
          <p>
          We specialize in Marking   Labeling Coding Weighing and Printing  Equipment Supplies Installation and Services Solutions
          </p>
        </div>
      </div>
      <div className="copyright">
        <p>copyright 2023 Datala Enterprises</p>
      </div>
    </div>
  );
}

export default footer;
