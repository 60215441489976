import "./App.css";
import { Routes, Route } from "react-router-dom";
import { Navbar, Brand, CTA } from "./components";
import { Header, Footer, Products, OurServices, OurTarget } from "./containers";
import Product from "./pages/Products.jsx";
import Contact from "./components/contact/contact.jsx";
import ProductPage from "./pages/product_page";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products" element={<Prod />} />
        <Route path="/contact" element={<Contact />} />

        <Route path="/target" element={<Target />} />
        <Route path="/products/:link" element={<PPage />} />
        <Route path="/services/:link" element={<PPage />} />

      </Routes>
    </>
  );
}

export default App;
function Home() {
  return (
    <>
      <div className="App">
        <div className="datala__header">
          <Navbar />
          <Header />
        </div>
        <div>
          <Brand />
        </div>
        <OurServices />
        {/* <OurTarget /> */}
        <Products />
        <CTA />
        <Footer />
      </div>
    </>
  );
}

function Prod() {
  return <Product />;
}

function Target() {
  return <OurTarget />;
}

function PPage() {
  return <ProductPage />;
}
