import React from 'react';
// import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router} from 'react-router-dom';

import App from './App';
import './index.css';
// ReactDOM.render(<App />,document.getElementById('root'));
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
 
    <Router>
      <App />
    </Router>
);
