import React from "react";
import "./otarget.css";
import { Navbar } from "../../components";
import { Footer } from "../../containers";
function OurTarget() {
  return ( 
    <div>
    <Navbar/>
    <div className="datala__target-list section__padding" id="target">
      <div className="datala__target-list_heading">
        <h3>Our Target Market</h3>
      </div>
      <div className="target_list" >
      <div className="datala__target-list_item">
        <ul class="myUL">
          <li>Food Processing</li>
          <li>Plastic Industries</li>
          <li>Branding and Labeling Industries</li>
          <li>Packaging Storage Houses</li>
          <li>Fishing Industry</li>
          <li>Oil Marketing Companies</li>
        </ul>
        </div>
        <div className="datala__target-list_item last"> 
        <ul class="myUL">
          <li>Beverage Manufacturing and Bottling</li>
          <li>Agricultural Processing</li>
          <li>Pharmaceuticals</li>
          <li> Refinery Industries</li>
          <li>Mining</li>

          <li>Freight and Shipping Industry</li>
        </ul>
        </div>
       
      </div>
  
      
    </div>
    <Footer/>
    </div>
  );
}

export default OurTarget;
