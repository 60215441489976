import { useParams, useLocation } from "react-router-dom";

import { Navbar } from "../components";
import { Footer } from "../containers";
import "../components/products/products.css";

function ProductPage() {
  // const {
  //   params: { ig2 },
  // } = match;
  const { link } = useParams();
  const location = useLocation();
  const { from, heading, img } = location.state;

  return (
    <>
      <div>
        <Navbar />

        <div className="citronix_">
        <h1 >{heading}</h1>

        </div>
        <div className="datala__products-item_detail">
          <img src={img} alt="Product"/>

          <p>{from}</p>
        </div>
        <Footer />
      </div>
    </>
  );
}
export default ProductPage;
