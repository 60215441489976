// <Link to="/about">About</Link>
// how to naviagate using link
import ProductItem from "../components/products/Products";
import { Navbar } from "../components";
import { Footer } from "../containers";

import {
  ci5200,
  ci5300,
  ci5500,
  ci5650,
  micro,
  hs50,
  contrast,
  high_contrast,
  general,
  dfc_grade,
  plastic_inks,
  high,
  low_odour,
  low_voc,
} from "../assets/image_index";

import React from "react";
import "./Products.css";

function Products() {
  return (
    <>
      <div>
        <Navbar />

        <div className="citronix_">
          <h1>Citronix Products</h1>
          <p>
            The ci5000 series range of Continuous Ink Jet (CIJ) printers is
            built upon Citronix’s long heritage and core values of Simple,
            Capable, Reliable, and Excellent Value, positioning it as one of the
            world’s leading CIJ brands.
          </p>
          <div className="citronix_products">
            <ProductItem
              text="Entry Level Printer"
              ig2={ci5200}
              link={"ci5200"}
              heading={"CI5200 ENTRY LEVEL PRINTER"}
              data="Entry level printer for typical 1 or 2 line printing applications at speeds of up to 6.9m/s. Designed with IP55 rated black powder coated steel cabinet with standard communication features configured for general purpose inks."
            />
            <ProductItem
              text="Intermediate Printer"
              ig2={ci5300}
              link={"ci5300"}
              heading={"CI5300 INTERMEDIATE PRINTER"}
              data="Intermediate printer in stainless steel cabinet to meet 1 to 3 line printing applications with Macro and Normal drop size at speeds of up to 9.8m/s. Designed for all non-pigment ink types."
            />
            <ProductItem
              text="High Performance Printer"
              ig2={ci5500}
              link={"ci550"}
              heading={"CI5500 HIGH PERFORMANCE PRINTER"}
              data="Advanced high performance printer with all the capabilities of ci5300 plus advanced connectivity features such as ciLink and capability to print up to 5 lines of text, graphics and bar codes at high speeds."
            />
            <ProductItem
              text="Harsh Environment Printer"
              ig2={ci5650}
              link={"ci5650"}
              heading={"CI5650 HARSH ENVIRONMENT PRINTER"}
              data="Harsh environment printer with positive air pressurized enclosure and printhead for extreme dust or washdown environments. Includes all features and capabilities of ci5500."
            />
          </div>
          {/* <h3>Citronix Products</h3> */}
          <p>
            Specialty printers for high speed, small character or colored ink
            applications are available in ci5500 and ci5650 models. Printhead
            and conduit can also be configured with right-angle bends and up to
            20 feet conduit lengths.
          </p>
          <div className="citronix_products">
            <ProductItem
              text="Small Character 40 Micron"
              ig2={micro}
              link="micro"
              heading={"MICRO - SMALL CHARACTER 40 MICRON"}
              data="Small character printer for applications requiring very small print heights (0.6mm to 6mm print height) at high resolution. This specialty option is available on ci5500 and ci5650 models."
            />
            <ProductItem
              text="High Speed 50 Micron"
              ig2={hs50}
              link="hs50"
              heading={"HS50 - HIGH SPEED 50 MICRON"}
              data="High speed printer for applications with above normal speed requirements. (Up to 10.6m/s) This specialty option is available on ci5500 and ci5650 models."
            />
            <ProductItem
              text="Pigment Ink Printer"
              ig2={contrast}
              link="contrast"
              heading={"CONTRAST - PIGMENTED INK PRINTER"}
              data="Contrast printer for applications that require exceptional contrast on complex substrates. These printers achieve the extra contrast by using pigmented inks. Printing both yellow and black pigment inks, this specialty option is available on ci5500 and ci5650 models."
            />
            <ProductItem
              text="White Ink Printer"
              ig2={high_contrast}
              link="high_contrast"
              heading={"HIGH CONTRAST - WHITE INK PRINTER"}
              data="High contrast printer for applications that require printing with white ink. Designed to meet the high demands of white ink, this specialty option is available on ci5500 an ci5650 models."
            />
          </div>
          <h1>Ink Range</h1>
          <div className="citronix_products">
            <ProductItem
              text="Offered in MEK, ACETONE, and ETHANOL, these inks provide good contrast on a wide range of substances."
              ig2={general}
              link={""}
            />
            <ProductItem
              text="DFC (Direct Food Contact) inks are FDA and EU approved for printing onto materials in direct contact with food."
              ig2={dfc_grade}
              link={""}
            />
            <ProductItem
              text="Suitable for printing onto PP, LDPE and HDPE and Materials with thin layers of moisture or oil."
              ig2={plastic_inks}
              link={""}
            />
            <ProductItem
              text="High contrast pigments are great for industrial applications on dark surfaces."
              ig2={high}
              link={""}
            />
            <ProductItem
              text="Low Volatile Organic Compounds(VOC) are suitable for the use as an alternative to MEK based inks."
              ig2={low_voc}
              link={""}
            />
            <ProductItem
              text="Great for dairy, bakery and confectionary industries that are susceptible to absorbing odours from their environments. "
              ig2={low_odour}
              link={""}
            />
          </div>
          {/* <h1>Ebs Products</h1> */}
          <div></div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Products;
