import React, { useState } from "react";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import logo from "../../assets/datalaentlogo.jpeg";
import "./navbar.css";

//get list of targeted sector with own list in the navbar
const Menu = ({menu}) => (

  <>
    <p>
      <a href="/">Home</a>
    </p>
    <p>
      <a href="/products">Products</a>
    </p>
{/* 
    <p>
      <a href="#services">Services</a>
    </p> */}
    <p>
      <a href="/target">Target</a>
    </p>
    <p>
      <a href="#about">About Us</a>
    </p>
    <p>
      <a href="/contact">Contact</a>
    </p>
  </>
);
function Navbar({menu}) {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="navbar__">
      <div className="navbar__links">
        <div className="navbar__links_logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="navbar_company_name">
          <p>Datala Enterprises (Pvt) Ltd</p>
        </div>
        <div className="navbar__links_container">
          <Menu />

        </div>
        <div className="navbar__menu">
          {toggleMenu ? (
            <RiCloseLine
              color="#000"
              size={27}
              onClick={() => setToggleMenu(false)}
            />
          ) : (
            <RiMenu3Line
              color="#000"
              size={27}
              onClick={() => setToggleMenu(true)}
            />
          )}
          {toggleMenu && (
            <div className="navbar__menu_container scale-up-center">
              <div className="navbar__menu_container-links">
                {/* <Menu />
                 */}
          <Menu />

              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
