import React from "react";
import logo from "../../assets/ci5300.jpg";
import "./header.css";
function header() {
  return (
    <div className="header__start section__padding" id="home">
      <div className="header__content ">
        <div className="header__content_image">
          <img src={logo} alt="logo" />
        </div>
        <div className="header__content_info">
          <h3>Welcome to Datala Enterprises (Pvt) Ltd</h3>
          <h1>Packaging, Labeling, Coding, Weighing & Printing Solutions</h1>
          <p>
            Datala Enterprises Private Limited are the approved, authorized
            importer and distributor of the Citronix, EBS, Makrotek CIJ Inkjet printers. We distribute
            labelers, consumables, TIJ,Drop On Demand (DOD),TTOs Thermal Transfer Overprint's,Laser Printers and related packaging products in the SADC
            region excluding South Africa.
          </p>
        </div>
      </div>
    </div>
  );
}

export default header;
