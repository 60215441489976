import React from "react";
import "./Brand.css";
import citronix from "../../assets/citronix_logo.png";
import ebs from "../../assets/ebs_svg_logo.svg";

function Brand() {
  return (
    <div className="brands__section ">
      <h1>Our Partners</h1>
      <div className="brands__section-logos">
        <div>
          <a href="http://www.citronix.co.za" target="_blank" rel="noreferrer">
            <img src={citronix} alt="citronix logo" />
          </a>
        </div>
        <div>
          <a href="https://signea.co.za/large-character-printers" target="_blank" rel="noreferrer">
            {" "}
            <img src={ebs} alt="ebs logo" />
          </a>
        </div>
        {/* <div>
      <img src={irp} alt='irp logo'/>

      </div> */}
      </div>
    </div>
  );
}

export default Brand;
