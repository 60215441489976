import React from "react";
import "./oservices.css";
import citronix from "../../assets/ci5500.jpeg";
import ebs from "../../assets/EBS_6600+main.jpg";
import large from "../../assets/1_EBS_260(s)_large.jpg";
import serv from "../../assets/hammer-g688b8cc33_640.png";

import { citrnix, ebss, workers } from "../../assets/image_index";
import { Link, BrowserRouter as Router, Route } from "react-router-dom";

function oservices() {
  return (
    <div className="datala__services section__padding">
      <div className="datala__services-heading " id="services">
        <h1>Our Services</h1>
        {/* <h2>What We Supply</h2> */}
      </div>
      <div className="datala__services-service">
        <div className="datala__services-service_item">
          <Link
            to={"/services/citronix"}
            state={{
              from: "Citronix offers a comprehensive range of continuous ink jet (CIJ) printers and inks to meet all your coding and marking needs. Citronix printers offer a non-contact way to code your products, whether plastic, metal, paper, wood, glass or other surface type, with one to five lines of high quality text, graphics and bar codes at very high speeds.",
              heading: "CONTINUOUS INKJET PRINTERS",
              img: citronix,
            }}
          >
            <img src={citronix} alt="Citronix" />
          </Link>
          <p>
            Citronix Ci5500 Inkjet Printers Simple Reliable Capable & Excellent
            Value to All Your Coding and Printing Solutions.
          </p>
        </div>
        <div className="datala__services-service_item">
          <Link
            to={"/services/ebs6600"}
            state={{
              from: "The continuous ink-jet system that leads the market in innovative design, easy operation, speed and sustainability.",
              heading: "EBS 6600",
              img: ebs,
            }}
          >
            <img src={ebs} alt="EBS Printer" />
          </Link>
          <p>
            EBS and Makrotek Continuous Inkjet Printer Small Character Printers
            (SCP)
          </p>
        </div>
        <div className="datala__services-service_item">
          <Link
            to={"/services/ebs260"}
            state={{
              from: "Whatever surface needs to be marked or coded, the HandJet EBS-260 will print precisely and clearly; concrete, metal, glass, textiles, plastic, cardboard or wood. You name it…the HandJet EBS-260 can mark it 7 to 52mm high anywhere in your office or anywhere you can imagine.",
              heading: "EBS 260",
              img: large,
            }}
          >
            <img src={large} alt="Large Character Printer" />
          </Link>
          <p>
            Large Character Inkjet Printers (LCP) and Print and Apply Labelers
            (PAL) Printers Sales
          </p>
        </div>
        {/* <div className="datala__services-service_item">
          <img src={irp} alt="irp " />
          <p>
            IRP Engineering Plastics and BMG Bearings Manufacturing Group
            Conveyor component Products
          </p>
        </div> */}
        <div className="datala__services-service_item">
          <Link
            to={"/services/after_sales_service"}
            state={{
              from: "Installation, Service, Repair and Maintenance for complete coding solutions",
              heading: "Services",
              img: workers,
            }}
          >
            <img src={serv} alt="services " />
          </Link>
          <p>
            Installation, Service, Repair and Maintenance for complete coding
            solutions
          </p>
        </div>
      </div>
    </div>
  );
}

export default oservices;
