import React, { useState } from "react";
import emailjs from "emailjs-com";
import { Navbar } from "../../components";
import { Footer } from "../../containers";

import "./contact.css";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  const submit = () => {
    if (name && email && message) {
      const serviceId = "service_2i2ti2b";
      const templateId = "template_jarfe85";
      const userId = "_EhTaXRDtaxijS93D";
      const templateParams = {
        from_name: name,
        message: message,
        reply_to: email,
      };

      emailjs
        .send(serviceId, templateId, templateParams, userId)
        .then((response) => {
          setName("");
          setEmail("");
          setMessage("");
          setEmailSent(true);
          return console.log(response);
        })
        .then((error) => console.log(error));
    } else {
      alert("Please fill in all fields.");
    }
  };

  return (
    <div>
      <Navbar />
      <div className="contact">
        <div>
          <div id="contact-form">
            <h1>WE'RE READY, LET'S TALK.</h1>
            <input
              type="text"
              placeholder="Your Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="email"
              placeholder="Your email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <textarea
              placeholder="Your message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>

          <button onClick={submit}>Send Message</button>

          <span className={emailSent ? "visible" : null}>
            Thank you for your message, we will be in touch in no time!
          </span>
        </div>
        <div>
          <h1>Contact Info</h1>
          <div className="contact_info">
            {/* <h4>Contact</h4> */}
            <p>55 Broughton Drive Sunridge, Harare, Zimbabwe</p>
            <p>+263 772 374 129</p>
            <p>+971 58 579 1936</p>
            <p>+278 4077 7635</p>
            <p>cham.runhare@gmail.com</p>
            <p>datalaent@gmail.com</p>
            <p>info@datalaent.com</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
